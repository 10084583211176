import React, { useState } from "react";
import { getShipping } from "../../store/actions/productDetails";
import { useDispatch } from "react-redux";
import Loader from "../Loader/index";
import { shippingModule } from "../../store/actions/shippingModule";
import { useToasts } from "react-toast-notifications";
import StepTimeline from "./StepTimeline";

const Shipping = (props) => {
  const [data, setData] = useState(null)
  const [enableForm, setEnableForm] = useState(true)

  React.useEffect(() => {
    if (props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].createdAt) {
      setData(props.orderData.shippingData[props.number - 1])
      let date_1 = new Date();
      let date_2 = new Date(props.orderData.shippingData[props.number - 1].createdAt);

      const days = (date_1, date_2) => {
        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.floor(difference / (1000 * 3600 * 24));
        return TotalDays;
      }
      if (days(date_1, date_2) < 2) {
        setEnableForm(true)
      }
      if (days(date_1, date_2) > 1) {
        setEnableForm(false)
      }
    }
    if (props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].driver_number) {
      setEnableForm(true)
    }
  }, [])
  const [driverName, setDriverName] = useState("");
  const [lotSize, setLotSize] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [driverNumber, setDriverNumber] = useState("");
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");
  const [showErrorName, setShowErrorName] = useState(false);
  const [showErrorNumber, setShowErrorNumber] = useState(false);
  const [pickupDate, setPickupDate] = useState("");
  const [showErrorDate, setShowErrorDate] = useState(false);
  const [showErrorDriverNumber, setShowErrorDriverNumber] = useState(false);
  const [showErrorImage, setShowErrorImage] = useState(false);
  const [showErrorLotSize, setShowErrorLotSize] = useState(false);
  const [showErrorFile, setShowErrorFile] = useState(false);
  const [showErrorUrl, setShowErrorUr] = useState(false);
  const [img, setImg] = useState("");
  const [fileAttach, setFileAttach] = useState({});
  const [imageAttach, setImageAttach] = useState({});
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [inputFields, setInputFields] = useState([{ vehicle: "" }]);
  const [submitDisable, setSubmitDisable] = useState(false)

  const [show, setShow] = useState(true);
  const { addToast } = useToasts();


  const imgHandler = (e, index) => {
    if(e.target.files[0]){
      setImageAttach({ ...imageAttach, [index]: e.target.files[0] });
      let src_ = URL.createObjectURL(e.target.files[0]);
      setImg(src_);
      setShowErrorImage(false);
    }
    else{
      setImageAttach({ ...imageAttach, [index]: null });
    }
  };
  const fileHandler = (e, index) => {
    if (e.target.files[0]) {
      setFileAttach({ ...fileAttach, [index]: e.target.files[0] });
      let src_ = URL.createObjectURL(e.target.files[0]);
      setImg(src_);
      setShowErrorFile(false);
    }
    else{
      setFileAttach({ ...fileAttach, [index]: null});
    }
  };

  const fileTypes = [
    "Lorry Receipt",
    "GST invoice",
    "Weighment slip",
    "Material Test Certificate",
    "Ewaybill",
  ];

  const fileNames = [
    "lorry_receipt",
    "gst_invoice",
    "weightment_slip",
    "material_test_certificate",
    "eway_bill"
  ]

  const handleRemoveSingle = () => {
    setShow(false);
    props.activeAdd(true)
    props.handleRemoveLot()
  };

  const handleAdd = () => {
    setInputFields([
      ...inputFields,
      {
        title: "",
        description: "",
        location: "",
      },
    ]);
  };

  const handleRemove = (index) => {
    if (inputFields.length !== 1) {
      const values = [...inputFields];
      values.splice(index, 1);
      setInputFields(values);
    }
  };

  const handleDriverName = (e) => {
    setDriverName(e.target.value);
    setShowErrorName(false);
  };

  const handleLotSize = (e) => {
    setLotSize(e.target.value);
    setShowErrorLotSize(false);
  };

  const handleDriverNumber = (e) => {
    setDriverNumber(e.target.value);
    setShowErrorDriverNumber(false);
  };

  const handleVehicleNumber = (e) => {
    setVehicleNumber(e.target.value);
    setShowErrorNumber(false);
  };

  const handlePickupDate = (e) => {
    setPickupDate(e.target.value);
    setShowErrorDate(false);
  };

  const handleSubmitInitial = (e) => {
    e.preventDefault();
    if (lotSize == "") {
      setShowErrorLotSize(true);
      return false;
    }
    if (pickupDate == "") {
      setShowErrorDate(true);
      return;
    }
    setShowLoader(true);
    shippingModule({ orderId: props.orderData._id, lot_number: props.number, lot_size: lotSize, pickup_date: pickupDate, tracking_status: 'Ready To Ship' }, (status) => {
      if (status.status == "success") {
        setShowLoader(false);
        // setSubmitDisable(true)
        props.activeAdd(true)
        addToast(status.message, {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
        window.location.reload(false);
      } else {
        setShowLoader(false);
        addToast(status.message, {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
      }
    })(dispatch);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    var expression =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    var regex = new RegExp(expression);

    // if (lotSize == "") {
    //   setShowErrorLotSize(true);
    //   return false;
    // }
    if (driverName == "") {
      setShowErrorName(true);
      return false;
    }
    if (driverNumber == "") {
      setShowErrorDriverNumber(true);
      return;
    }
    if (vehicleNumber == "") {
      setShowErrorNumber(true);
      return;
    }
    // if (pickupDate == "") {
    //   setShowErrorDate(true);
    //   return;
    // }

    if (Object.keys(fileAttach).length < 1) {
      setShowErrorFile(true);
      return;
    } else if (Object.keys(imageAttach).length < 1) {
      setShowErrorImage(true);
      return;
    } else {
      setShowLoader(true);
      setShowMessage("Please wait...");
      let payload = {
        orderId: props.orderData._id,
        lot_number: props.number,
        driver_name: driverName,
        driver_number: driverNumber,
        vehicle_number: vehicleNumber,
        tracking_url: url,
        lot_id: (props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].lot_id || null),
        additionalComment: message
      };
      let formdata = new FormData();
      for (let item in payload) {
        formdata.append(item, payload[item]);
      }
      for (let item in imageAttach) {
        formdata.append("media", imageAttach[item]);
      }
      formdata.append("lorry_receipt", fileAttach["Lorry Receipt"]);
      formdata.append("gst_invoice", fileAttach["GST invoice"]);
      formdata.append("weightment_slip", fileAttach["Weighment slip"]);
      formdata.append("material_test_certificate", fileAttach["Material Test Certificate"]);
      formdata.append("eway_bill", fileAttach["Ewaybill"]);
      // formdata.append("images", imageAttach);

      shippingModule(
        formdata
        , (status) => {
          if (status.status == "success") {
            setSubmitDisable(true)
            setShowLoader(false);
            addToast(status.message, {
              appearance: "success",
              autoDismissTimeout: 2500,
            });
            window.location.reload()
          } else {
            setShowLoader(false);
            addToast(status.message, {
              appearance: "error",
              autoDismissTimeout: 2500,
            });
          }
        })(dispatch);

      // dispatch(
      //   getShipping(formdata, (callback) => {
      //     props.load();
      //     setShowErrorName(false);
      //     setShowErrorNumber(false);
      //     setShowErrorImage(false);
      //     setShowErrorUr(false);
      //     setShowLoader(false);
      //   })
      // );
    }
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 2);
  const minDate = tomorrow.toISOString().split("T")[0];

   // Determine steps with previous steps activation
   let trackingStatus =
   props.orderData.shippingData[props.number - 1]  ? props.orderData.shippingData[props.number - 1].tracking_status : "";

   const lot = props.orderData.shippingData && props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].lot_number ? props.orderData.shippingData[props.number - 1].lot_number : ''

   if(props.completeOrderdetail.received_shipping_status && props.completeOrderdetail.received_shipping_status[lot - 1] && props.completeOrderdetail.received_shipping_status[lot - 1].isReceived) {
    trackingStatus = 'Goods Received'
   }

  const steps = [
    { name: "Ready To Ship" },
    { name: "Goods Dispatched" },
    { name: "Goods Received" },
  ].map((step, index, allSteps) => {
    // Find the index of the current tracking status
    const activeIndex = allSteps.findIndex(
      (s) => s.name === trackingStatus
    );

    return {
      ...step,
      isActive: index <= activeIndex, // Mark step active if it's at or before the current active step
    };
  });

  return (
    <>
      {show && (
        <div className="shipping-module-wrp">
          {showLoader ? <Loader message={showMessage} /> : null}
          <div
            className={`remove-form-shipping-wrap ${props.number > 1 ? "have-border" : ""
              }`}
          >
            <h4>Lot {(props.orderData.shippingData.length && props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].lot_number) ? props.orderData.shippingData[props.number - 1].lot_number:props.number}</h4>
            <StepTimeline
              steps={steps}
            />
            {submitDisable || props.number > 1 && !(props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].lot_size) && (
              <button
                className="remove-form-shipping"
                onClick={handleRemoveSingle}
              ></button>
            )}
          </div>
          {console.log("props1111",props)}

          <div className="form-wrapper-new">
            {props.orderData.isShipping == false &&
              props.userType == "Seller" ? (
              <form>
                <div className="full-form-block separate-form-shipping lot-date-wrapper">
                  <div className="form-block-error-wrp form-block-new">
                    <div className="form-block">
                      <label>
                        Lot size <span className="astrisk-sign">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter lot size"
                        className="form-control"
                        disabled={props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].lot_size ? true : false}
                        defaultValue={
                          props.orderData.shippingData[props.number - 1]
                            ? props.orderData.shippingData[props.number - 1].lot_size
                            : ""
                        }
                        onChange={(e) => handleLotSize(e)}
                      />
                    </div>
                    {showErrorLotSize ? (
                      <span className="error">Please enter lot size</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-block-error-wrp form-block-new">
                    <div className="form-block">
                      <label>
                        Pickup date <span className="astrisk-sign">*</span>
                      </label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="date"
                          placeholder="Enter Pickup Date"
                          className="form-control"
                          value={
                            props.orderData.shippingData[props.number - 1]
                              ? props.orderData.shippingData[props.number - 1].pickup_date
                              : pickupDate
                          }
                          disabled={props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].pickup_date ? true : false}
                          // value={pickupDate}
                          // min={minDate}
                          onChange={(e) => handlePickupDate(e)}
                        />
                      </div>
                    </div>
                    {showErrorDate ? (
                      <span className="error">Please enter pickup date</span>
                    ) : (
                      ""
                    )}
                  </div>
                  {props.orderData.order_status !== "Completed" && <div
                    className="btn-wrapper-submit"
                    style={{ marginTop: "33px", justifyContent: "flex-end" }}
                  >
                    <button
                      className="btn"
                      type="submit"
                      onClick={handleSubmitInitial}
                      disabled={(props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].pickup_date) || submitDisable}
                    >
                      Submit
                    </button>
                  </div>}
                </div>

                <div className="full-form-block">
                  <div className="form-block-error-wrp form-block-new">
                    <div className="form-block">
                      <label>
                        Driver name <span className="astrisk-sign">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter driver name"
                        className="form-control"
                        defaultValue={
                          props.orderData.shippingData[props.number - 1]
                            ? props.orderData.shippingData[props.number - 1].driver_name

                            : ""
                        }
                        disabled={props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].driver_name ? true : false}
                        onChange={(e) => handleDriverName(e)}
                      />
                    </div>
                    {showErrorName ? (
                      <span className="error">Please enter driver name</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-block-error-wrp form-block-new">
                    <div className="form-block">
                      <label>
                        Driver number <span className="astrisk-sign">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter driver number"
                        className="form-control"
                        defaultValue={
                          props.orderData.shippingData[props.number - 1]
                            ? props.orderData.shippingData[props.number - 1].driver_number

                            : ""
                        }
                        disabled={props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].driver_number ? true : false}
                        onChange={(e) => handleDriverNumber(e)}
                      />
                    </div>
                    {showErrorDriverNumber ? (
                      <span className="error">Please enter driver number</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="full-form-block">
                  <div className="form-block-error-wrp form-block-new">
                    <div className="form-block">
                      <label>
                        Vehicle number <span className="astrisk-sign">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter vehicle number"
                        className="form-control"
                        defaultValue={
                          props.orderData.shippingData[props.number - 1]
                            ? props.orderData.shippingData[props.number - 1].vehicle_number


                            : ""
                        }
                        disabled={props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].vehicle_number
                          ? true : false}
                        onChange={(e) => handleVehicleNumber(e)}
                      />
                    </div>
                    {showErrorNumber ? (
                      <span className="error">Please enter vehicle number</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-block-error-wrp form-block-new">
                    <div className="form-block">
                      <label>Tracking URL</label>
                      <input
                        type="url"
                        placeholder="Enter tracking URL"
                        className="form-control"
                        defaultValue={
                          props.orderData.shippingData[props.number - 1]
                            ? props.orderData.shippingData[props.number - 1].tracking_url


                            : ""
                        }
                        disabled={props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].tracking_url
                          ? true : false}
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="full-form-block" style={{ display: "block" }}>
                  <div
                    className="form-block form-block-new"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="form-blockk-img-wrp"
                      style={{ marginBottom: "10px" }}
                    >
                      <div className="form-block">
                        <label>
                          Upload document{" "}
                          <span className="astrisk-sign">*</span>
                        </label>
                        <div className="file-img-inputs progress-file-wrp">
                          {[1, 2, 3, 4, 5].map((item, index) => {
                            return !fileAttach[fileTypes[index]] ? (
                              <div>
                                {props.orderData.order_status !== "Completed" ? <div
                                  className={`input-file-wrp-shipping ${props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].driver_number ? "disabled" : ""}`}
                                  key={item}
                                >
                                  <i class="fas fa-plus"></i>
                                  <input
                                    type="file"
                                    className="file-in"
                                    name="upload"
                                    accept=".pdf"
                                    disabled={props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].driver_number
                                      ? true : false}
                                    onChange={(e) => fileHandler(e, fileTypes[index])}
                                  />
                                </div> :
                                  <a href={props.orderData.shippingData[props.number - 1][fileNames[index]]} target="_blank" className="btn download-btn-wrp" style={{ padding: "9px 23px", color: "rgb(25, 135, 84)" }}>Download</a>
                                }
                                <h6>{fileTypes[index]}</h6>
                              </div>
                            ) : (
                              <div>
                                <div
                                  className="uploaded-img-view"
                                  key={item}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "relative"
                                  }}
                                >
                                  <i
                                    style={{ fontSize: "28px" }}
                                    className={
                                      fileAttach[fileTypes[index]].type === "application/pdf"
                                        ? "fas fa-file-pdf green-icon-pdf"
                                        : "far fa-file"
                                    }
                                  ></i>
                                  <input
                                    type="file"
                                    className="file-in after-upload-input"
                                    name="upload"
                                    accept=".pdf"
                                    disabled={(props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].driver_number) || submitDisable
                                      ? true : false}
                                    onChange={(e) => fileHandler(e, fileTypes[index])}
                                  />
                                  {/* <img className={} src={fileAttach[index].type==="application/pdf" ? "fas fa-file-pdf" : "far fa-file"} alt="view-img" /> */}
                                </div>
                                <h6 style={{ width: "100px" }}>{fileTypes[index]}</h6>
                              </div>
                            );
                          })}
                        </div>
                        {/* {img !== "" && (
                        <div className="uploaded-img-view" key={index}>
                        <img src={URL.createObjectURL(index)} alt="view-img" />
                      </div>
                    )} */}
                      </div>
                      {showErrorFile ? (
                        <span className="error">Please upload documents</span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-blockk-img-wrp form-main-block">
                      <div className="form-block">
                        <label>
                          Upload loading pictures{" "}
                          <span className="astrisk-sign">*</span>
                        </label>
                        {props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].mediaImages ?
                          <div className="file-img-inputs progress-file-wrp">

                            {props.orderData.shippingData[props.number - 1].mediaImages.map((item, index) => {
                              return <a href={item.mediaData} target={"_blank"} className="uploaded-img-view" key={index}>
                                <img
                                  src={item.mediaData}
                                  alt="view-img"
                                />
                              </a>
                            })}
                          </div>

                          : <div className="file-img-inputs progress-file-wrp">
                            {[1, 2, 3, 4, 5].map((item, index) => {
                              return !imageAttach[index] ? (
                                <div
                                  className="input-file-wrp-shipping"
                                  key={item}
                                >
                                  <i class="fas fa-plus"></i>
                                  <input
                                    type="file"
                                    className="file-in"
                                    name="upload"
                                    accept=".png,.jpeg,.jpg"
                                    onChange={(e) => imgHandler(e, index)}
                                  />
                                </div>
                              ) : (
                                <div className="uploaded-img-view" style={{ position: "relative" }}>
                                  <img
                                    src={URL.createObjectURL(imageAttach[index])}
                                    alt="view-img"
                                  />
                                  <input
                                    type="file"
                                    className="file-in after-upload-input"
                                    name="upload"
                                    accept=".png,.jpeg,.jpg"
                                    onChange={(e) => imgHandler(e, index)}
                                    disabled={submitDisable}
                                  />
                                </div>
                              );
                            })}
                          </div>}
                      </div>
                      {showErrorImage ? (
                        <span className="error">
                          Please upload loading pictures
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    className="form-block form-block-new"
                    style={{ width: "100%", marginTop: "20px" }}
                  >
                    <label>Additional notes</label>
                    <textarea
                      id="w3review"
                      placeholder="Enter additional notes"
                      rows="4"
                      cols="50"
                      className="shipping-text-area"
                      value={
                        props.orderData.shippingData[props.number - 1] ? props.orderData.shippingData[props.number - 1].additionalComment
                          : message
                      }
                      disabled={(props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].driver_number)
                        ? true : false}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </div>
                {props.orderData.order_status !== "Completed" && <div
                  className="btn-wrapper-submit"
                  style={{ marginTop: "20px", justifyContent: "flex-end" }}
                >
                  <button className="btn" disabled={!(props.orderData.shippingData[props.number - 1] && props.orderData.shippingData[props.number - 1].lot_id && (!props.orderData.shippingData[props.number - 1].driver_number)) || submitDisable} type="submit" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>}
              </form>
            ) : props.orderData.isShipping == true &&
              (props.userType == "Seller" || props.userType == "Buyer") ? (
              <div className="form-output-wrapper">
                <div className="value-wrap">
                  <span className="name">vehicle Name : </span>
                  <span className="value">
                    {props.orderData.shippingData.vehicleName}
                  </span>
                </div>

                <div className="value-wrap">
                  <span className="name">vehicle Number : </span>
                  <span className="value">
                    {props.orderData.shippingData.vehicleNumber}
                  </span>
                </div>

                <div className="value-wrap">
                  <span className="name">Message : </span>
                  <span className="value">
                    {props.orderData.shippingData.message}
                  </span>
                </div>

                <div className="value-wrap">
                  <span className="name">Url : </span>
                  <span className="value">
                    {props.orderData.shippingData.url}
                  </span>
                </div>

                <div className="value-wrap">
                  <span className="name">Receipt : </span>
                  <span className="value">
                    <div className="reciept-block-img">
                      <img
                        src={props&&props.orderData&&props.orderData.shippingData&&props.orderData.shippingData.docs&&
                          props.orderData.shippingData.docs[0]&&props.orderData.shippingData.docs[0].url}
                        alt="receipt-img"
                      />
                    </div>
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default Shipping;
