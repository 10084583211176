import moment from "moment";
import React, { useState } from "react";
import { priceWithSymbol } from "../../utils/constants";
import {
  updateManualShippingStatus,
  acceptThisOrder,
  rejectThisOrder,
  acceptThisOffer,
  rejectThisOffer,
  stock_entry_header,
  generate_label,
  generate_manifest,
} from "../../store/actions/profile";
import { useToasts } from "react-toast-notifications";
import InputReason from "../Profile/MainProfile/MyOrder/inputReason";
import ReviceOffer from "./ReviceOffer";
import { Modal, Button, Form } from "react-bootstrap";
import { Cost_Estimation } from "../../store/actions/productDetails";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import "./modal.css";
import { SoldSpinnerLoader } from "../../utils/constants";
import { Card } from "react-bootstrap";
import swal from "sweetalert";
import { getorderDetail } from "../../store/actions/profile";
import Toast from "light-toast";
import { useLocation } from "react-router-dom";
import MoreAction from "./moreAction";
import DownloadBtn from "./downloadBtn";
import DeliveryAddress from "./deliveryAddress";
import Loader from "../Loader/index";
import DeliveryStep from "./deliveryStep";
import MakePayment from "./makePayment";
import Shipping from "./shipping";
import ChangeTrack from "./changeTracking";
import OrderReceived from "./orderReceived";
import ShippingBuyer from "./shippingBuyer";
import { uploadInvoice } from "../../store/actions/uploadInvoice";
import ShippingModuleBuyer from "./shippingModuleBuyer";
import { receiveAllOrder } from "../../store/actions/receiveAllOrder";
import Countdown from "react-countdown";
import { axiosInstance } from "../../utils/Service";
import StepTimeline from "./StepTimeline";

const PendingOrderDetail = (props) => {
  console.log("pendingorder", props);
  const { addToast } = useToasts();
  let dispatch = useDispatch();

  const [isExpanded, setExpanded] = React.useState(false);
  const [showInputForm, setInpuForm] = React.useState(false);
  const [rejectOrderID, setRejectOrderId] = React.useState("");
  const [type, setType] = React.useState(""); // normal or offer
  const [reviseOfferPopup, setReviseOffer] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [show1, setshow1] = React.useState(false);
  const [width, setwidth] = React.useState();
  const [height, setheight] = React.useState();
  const [length, setlength] = React.useState();
  const [weight, setweight] = React.useState();
  const [feedbackPopup, setFeedbackPopup] = React.useState(false);
  const [commentBox, setCommentBox] = React.useState("");
  const [selectFeedback, setSelectFeedback] = React.useState("");
  const [feedbackReason, setFeedbackReason] = React.useState(null);
  const [reasonList, setReasonList] = React.useState(null);
  const [imageAttach, setImageAttach] = React.useState([]);
  const [errorImages, setErrorImages] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState(false);
  const [errorSelectFeedback, setErrorSelectFeedback] = React.useState(false);
  const [HeaderId, setHeaderId] = React.useState(false);
  const [labelOrderId, setlabelOrderId] = React.useState(null);
  const history = useHistory();
  const [costEstimateListDetail, setcostEstimateListDetail] = React.useState(
    []
  );
  const [labelGenerated, setlabelGenerated] = React.useState(null);
  const [manifestGenerated, setmanifestGenerated] = React.useState(null);
  const [TotalDiscount, setTotalDiscount] = React.useState(false);
  const location = useLocation();
  const [orderstatus, setOrderstatus] = React.useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const selectorData = useSelector(
    (state) => state.userProfile.userProfileBanner.userdata
  );
  const [userData, setUserData] = useState(selectorData);
  const [allSteps, setAllSteps] = React.useState([]);

  const [formCount, setFormCount] = useState([1]);

  const [fileAttach, setFileAttach] = useState();
  const [img, setImg] = useState("");

  const [invoiceData, setInvoiceData] = useState(null);

  const [orderStatusActive, setOrderStatusActive] = useState(false);
  const [activeAddLot, setActiveAddLot] = useState(false);
  const [invoiceError, setInvoiceError] = useState(false);
  const [receiveOrders, setReceiveOrders] = useState(false);
  const [showReceivedSection, setShowReceivedSection] = useState(false);
  const [allReceived, setAllReceived] = useState(false);

  React.useEffect(() => {
    setShowLoader(true);
    let token = localStorage.getItem("token");
    axiosInstance
      .post(
        `/reportSeller/reported_reason_list`,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setReasonList(res.data.data);
        setShowLoader(false);
      })
      .catch(() => {
        setShowLoader(false);
      });
  }, []);

  React.useEffect(() => {
    setUserData(selectorData);
  }, [selectorData]);

  React.useEffect(() => {
    setAllReceived(props.completeOrderdetail.isReceivedAllProducts);
  }, [props.completeOrderdetail.isReceivedAllProducts]);

  React.useEffect(() => {
    if (
      props.completeOrderdetail.received_shipping_status &&
      props.completeOrderdetail.received_shipping_status.length > 0
    ) {
      setActiveAddLot(true);
    }
    if (props.completeOrderdetail.received_shipping_status) {
      props.completeOrderdetail.received_shipping_status.forEach((item) => {
        let date_1 = new Date();
        let date_2 = new Date(item.createdAt);

        const days = (date_1, date_2) => {
          let difference = date_1.getTime() - date_2.getTime();
          let TotalDays = Math.floor(difference / (1000 * 3600 * 24));
          return TotalDays;
        };
        if (days(date_1, date_2) > 1) {
          setOrderStatusActive(true);
          return;
        }
      });
    }
    if (props.completeOrderdetail.received_shipping_status) {
      props.completeOrderdetail.received_shipping_status.forEach((item) => {
        if (item.media.length > 0) {
          setShowReceivedSection(true);
          return;
        }
      });
    }
  }, [props.completeOrderdetail.received_shipping_status]);

  React.useEffect(() => {
    if (
      props.orderdetail &&
      props.orderdetail.shippingData &&
      props.orderdetail.shippingData.length
    ) {
      let counter = [];
      props.orderdetail.shippingData.forEach((item, index) => {
        counter = [...counter, index + 1];
      });
      setFormCount(counter);
    }
    if (props.orderdetail && props.orderdetail.performaInvoice) {
      setInvoiceData(props.orderdetail.performaInvoice);
    }
    setExpanded(true);
    // if (props.orderdetail.coupon_price.$numberDecimal > 0) {
    //     setTotalDiscount(true);
    // }
    localStorage.setItem("setlabelOrderId", props.orderdetail.headerId);
    setlabelGenerated(props.orderdetail.labelGenerated);
    setmanifestGenerated(props.orderdetail.manifestGenerated);
    if (props.orderdetail.headerId) {
      setHeaderId(true);
    } else {
      setHeaderId(false);
    }
  }, []);

  const handleSelectFeedback = (e) => {
    setSelectFeedback(e.target.value);
    setErrorSelectFeedback(false);
    reasonList.forEach((item) => {
      if (e.target.value === item._id) {
        setFeedbackReason(item.reason);
      }
    });
  };

  const handleFeedbackFiles = (e) => {
    setErrorImages(false);
    if (e.target.files && e.target.files[0]) {
      setImageAttach(e.target.files);
    }
  };

  const handleFeedbackSubmit = () => {
    let formData = new FormData();

    if (selectFeedback.length == 0) {
      setErrorSelectFeedback(true);
      return;
    }

    if (commentBox.length == 0) {
      setErrorDescription(true);
      return;
    }
    if (imageAttach.length == 0) {
      setErrorImages(true);
      return;
    }
    formData.append(
      "orderId",
      props.orderdetail && props.orderdetail._id ? props.orderdetail._id : ""
    );
    formData.append(
      "sellerId",
      props.orderdetail && props.orderdetail.seller_id
        ? props.orderdetail.seller_id
        : ""
    );
    formData.append("reasonId", selectFeedback);
    formData.append("additionalComment", commentBox);
    for (let element of imageAttach) {
      formData.append("media", element);
    }
    setShowLoader(true);
    let token = localStorage.getItem("token");
    axiosInstance
      .post(`/reportSeller/report_seller`, formData, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setShowLoader(false);
        window.location.reload();
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  const close = () => {
    setShow(false);
  };
  const closeBtn = () => {
    setShow(false);
  };
  const acceptOrder = (data) => {
    setShowLoader(true);
    const payload = { orderId: [data._id] };
    acceptThisOrder(payload, (status) => {
      if (status.status) {
        setShowLoader(false);
        addToast(status.data.message, {
          appearance: "success",
          autoDismissTimeout: 4000,
        });
        props.loadDetails();
        setTimeout(() => {
          props.history.push("/profile/seller-order/processed");
        }, 1000);
      }
    });
  };

  const rejectOrder = (id, reason) => {
    const payload = { orderId: [id], reason: reason };
    rejectThisOrder(payload, (status) => {
      if (status.status) {
        addToast(
          status.data.message,
          {
            appearance: "success",
            autoDismissTimeout: 4000,
          },
          () => {
            setRejectOrderId("");
            setType("");
          }
        );
        props.loadDetails();
      }
    });
  };

  const acceptOffer = (data) => {
    const payload = { orderId: [data._id] };
    acceptThisOffer(payload, (status) => {
      if (status.status) {
        addToast(status.data.message, {
          appearance: "success",
          autoDismissTimeout: 4000,
        });
        props.loadDetails();
      }
    });
  };

  const rejectOffer = (id, reason) => {
    const payload = { orderId: [id], reason: reason };
    rejectThisOffer(payload, (status) => {
      if (status.status) {
        addToast(
          status.data.message,
          {
            appearance: "success",
            autoDismissTimeout: 4000,
          },
          () => {
            setRejectOrderId("");
            setType("");
          }
        );
        props.loadDetails();
      }
    });
  };

  const revisedOffer = (price) => {
    const payload = {
      orderId: [rejectOrderID],
      reason: "  ",
      revisePrice: price,
    };
    rejectThisOffer(payload, (status) => {
      if (status.status) {
        addToast(
          status.data.message,
          {
            appearance: "success",
            autoDismissTimeout: 4000,
          },
          () => {
            setRejectOrderId("");
            setType("");
          }
        );
        props.loadDetails();
      }
    });
  };

  function carrierDetail(e) {
    setShowLoader(true);
    setShowMessage("Please wait...");

    e.preventDefault();
    var tokenData = JSON.parse(localStorage.getItem("ShippingTokenDetail"));

    var order_id = props.orderdetail._id;
    const carrierObject = {
      authToken: tokenData.token,
      organizationId: tokenData.organizationId,
      orderId: order_id,
      breadth: weight,
      height: height,
      length: length,
      weight: width,
    };

    props.Cost_Estimation(carrierObject, (status) => {
      if (status) {
        setShowLoader(false);
        setShow(false);
        setshow1(true);

        setcostEstimateListDetail(status.costEstimateList);
        props.loadDetails();
      } else {
        setShowLoader(false);
        props.loadDetails();
      }
    });
  }

  function getOrder(e) {
    swal({
      title: "Are you sure?",
      text: "You want to create a order",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setShowLoader(true);
        setShowMessage("Please wait...");

        const result = costEstimateListDetail.filter(
          (word) => word.carrierCode == e
        );

        var tokenData = JSON.parse(localStorage.getItem("ShippingTokenDetail"));
        var order_id = props.orderdetail._id;
        var payload = {
          // pass user login token in headers
          authToken: tokenData.token,
          accountOwnerId: tokenData.accountOwnerId,
          organizationId: tokenData.organizationId,
          orderId: order_id,
          shipmentPrice: result[0].costEstimates[0].totalAmount,
          shippingOption: result[0].carrierCode,
        };

        props.stock_entry_header(payload, (status) => {
          if (status) {
            setShowLoader(false);
            setHeaderId(true);
            setShow(false);
            setshow1(false);
            setlabelOrderId(status.id);
            localStorage.setItem("setlabelOrderId", status.id);
          } else {
            setShowLoader(false);
            setShow(false);
            setshow1(false);
          }
        });
      } else {
      }
    });
  }

  function getLable() {
    setShowLoader(true);
    setShowMessage("PLease wait...");
    var tokenData = JSON.parse(localStorage.getItem("ShippingTokenDetail"));
    var order_id = localStorage.getItem("setlabelOrderId");

    var payload = {
      // pass user login token in header
      authToken: tokenData.token,
      accountOwnerId: tokenData.accountOwnerId,
      organizationId: tokenData.organizationId,
      orderId: order_id,
    };

    props.generate_label(payload, (status) => {
      if (status) {
        setShowLoader(false);
        window.open(status, "_blank");

        getOrderData();

        //window.location.reload();
      } else {
        setShowLoader(false);
      }
    });
  }

  function getOrderData() {
    var Orderpayload = {
      orderId: props.orderdetail._id,
      type: "Processed",
      view: "seller",
    };

    props.getorderDetail(Orderpayload, (status) => {
      if (status) {
        setlabelGenerated(status.data[0].labelGenerated);
      } else {
      }
    });
  }

  function handleUploadInvoice() {
    let formdata = new FormData();
    formdata.append("performa_invoice", fileAttach);
    formdata.append("userId", props.completeOrderdetail.Data.seller_id);
    formdata.append("orderId", props.completeOrderdetail.Data._id);
    // uploadInvoice(formdata)
    if (fileAttach) {
      setShowLoader(true);

      uploadInvoice(formdata, (status) => {
        if (status.status == "success") {
          setShowLoader(false);
          setInvoiceData(status.data);
          addToast(status.message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
        } else {
          setShowLoader(false);
          addToast(status.message, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
      })(dispatch);
    } else {
      setInvoiceError(true);
    }
  }

  function getManifest() {
    Toast.loading("Loading...");

    var tokenData = JSON.parse(localStorage.getItem("ShippingTokenDetail"));
    var order_id = localStorage.getItem("setlabelOrderId");
    var payload = {
      // pass user login token in header
      authToken: tokenData.token,
      accountOwnerId: tokenData.accountOwnerId,
      organizationId: tokenData.organizationId,
      headerIds: [order_id],
    };

    props.generate_manifest(payload, (status) => {
      if (status) {
        Toast.hide();

        window.open(status, "_blank");
        setmanifestGenerated(props.orderdetail.manifestGenerated);
        var Orderpayload = {
          orderId: props.orderdetail._id,
          type: "Processed",
          view: "seller",
        };

        props.getorderDetail(Orderpayload, (status) => {
          if (status) {
            setmanifestGenerated(status.data[0].manifestGenerated);
          } else {
          }
        });

        // window.location.reload();
      } else {
        Toast.hide();
        swal("Something went wrong");
      }
    });
  }

  const previous = () => {
    setShow(true);
    setshow1(false);
  };

  const handleClose1 = () => {
    setshow1(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  const shippingHandler = (e) => {
    let val = e.target.value;
    setOrderstatus(val);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (orderstatus !== "") {
      updateManualShippingStatus(
        { status: orderstatus, orderId: props.orderdetail._id },
        (callback) => {
          if (callback.status === "success") {
            addToast(callback.message, {
              appearance: "success",
              autoDismissTimeout: 2000,
            });
          } else {
            addToast(callback.message, {
              appearance: "error",
              autoDismissTimeout: 2000,
            });
          }
        }
      )(dispatch);
    }
  };

  const handleBack = () => {
    if (props.type == "Pending") {
      props.history.push("/profile/myorder/pending");
    } else if (props.type == "Processed") {
      props.history.push("/profile/myorder/processed");
    }
  };

  const fileHandler = (e) => {
    setFileAttach(e.target.files[0]);
    let src_ = URL.createObjectURL(e.target.files[0]);
    setImg(src_);
    setInvoiceError(false);
  };

  const handleReceiveAll = () => {
    setShowLoader(true);
    receiveAllOrder(
      {
        orderId: props.orderdetail._id,
      },
      (status) => {
        if (status.status == "success") {
          setAllReceived(status.isReceivedAllProducts);
          setShowLoader(false);
          setReceiveOrders(true);
          addToast(status.message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
        } else {
          setShowLoader(false);
          addToast(status.message, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
      }
    )(dispatch);
  };

  const handleRemoveLot = () => {
    let tempLotNumber = [...formCount];
    tempLotNumber.splice(tempLotNumber.length - 1, 1);
    setFormCount(tempLotNumber);
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  const getDelieveryText = (props) => {
    const orderDetail = props.orderdetail;
    const productDetail = orderDetail.product_detail;
    if (!productDetail) {
      return "";
    }

    const deliveryType =
      orderDetail.orderType === "Offered"
        ? productDetail.offerDetails &&
          productDetail.offerDetails.offerData &&
          productDetail.offerDetails.offerData.length > 0
          ? productDetail.offerDetails.offerData[
            productDetail.offerDetails.offerData.length - 1
          ].deliveryType
          : null
        : productDetail.deliveryType;

    const cityName =
      productDetail.cityData && productDetail.cityData[0].cityName;

    const displayText =
      deliveryType === "FOR"
        ? deliveryType
        : deliveryType
        ? `${deliveryType} ${cityName}`
        : cityName;

    return displayText;
  };

  const steps = [
    { name: "Processing", isActive: true },
    { name: "Dispatching", isActive: props.orderdetail.shippingData[0] ? true : false },
    {
      name: "Order Complete",
      isActive: props.completeOrderdetail && props.completeOrderdetail.Data.current_status === 'GoodsReceived',
    },
  ].map((step, index, allSteps) => {
    // For each step, we check if it's the last step
    if (index === allSteps.length - 1) {
      // For the last step, ensure it’s only marked as active if all previous steps are also active
      return {
        ...step,
        isActive: allSteps.every((s, idx) => idx <= index ? s.isActive : true), // All previous steps must be active
      };
    } else {
      // For non-last steps, just return as is, based on the original logic
      return step;
    }
  });

  // Find the latest active step and get its name to show in Status field
  const latestActiveStepName = [...steps].reverse().find(step => step.isActive).name;

  return props.orderdetail !== "" && userData ? (
    <div className="multiple-items multiple-items-wrapper new-multiple-wrap fixing-items">
      {showLoader ? <Loader message={showMessage} /> : null}
      <div className="container">
        <div className="ordered-products order-product-wrp">
          <button
            className="chat-module__back-button order-bck-page"
            onClick={() => props.history.goBack()}
          >
            {/* <i></i> */}
            <span className="bck-order">
              <i></i> Back
            </span>
          </button>
          <div className="products-images products-images-wrapper pending-order">
            <div className="img-wrapper">
              <img
                src={props.orderdetail.product_detail.default_image}
                alt={"Images"}
              />
            </div>
            <div className="product-detail">
              <div className="cart-item-detail">
                <div className="value">
                  <div className="current-value">Product Name:</div>
                  <div className="new-value">
                    {props.orderdetail.product_detail.productName}{" "}
                    {props.orderdetail.product_detail.productGrade}&nbsp;
                  </div>
                </div>
                {props.orderdetail.unit_price ? (
                  <div className="value">
                    <div className="current-value">Price (PMT):</div>
                    <div className="new-value">
                      ₹
                      {Math.trunc(
                        Number(
                          props.orderdetail.orderType === "Offered"
                            ? props.orderdetail.product_detail.offerDetails &&
                            props.orderdetail.product_detail.offerDetails
                              .final_offer_price &&
                            props.orderdetail.product_detail.offerDetails
                              .final_offer_price
                            : props.orderdetail.unit_price
                        )
                      )}
                    </div>
                  </div>
                ) : null}
                <div className="value">
                  <div className="current-value">Total Price:</div>
                  <div className="new-value">
                    ₹{props.orderdetail.total_order_amount.toFixed(2)}
                  </div>
                </div>
                {props &&
                  props.orderdetail &&
                  props.orderdetail.product_detail &&
                  props.orderdetail.product_detail.specificationTolerance && (
                    <div className="value">
                      <div className="current-value">
                        Specification Tolerance:
                      </div>
                      <div className="new-value">
                        {
                          props.orderdetail.product_detail
                            .specificationTolerance
                        }
                        %
                      </div>
                    </div>
                  )}
                <div className="value">
                  <div className="current-value">Payment Type:</div>
                  <div className="new-value">
                    {props.orderdetail.orderType === "Offered"
                      ? props.orderdetail.product_detail.offerDetails &&
                      props.orderdetail.product_detail.offerDetails
                        .offerData &&
                      props.orderdetail.product_detail.offerDetails.offerData
                        .length > 0 &&
                      props.orderdetail.product_detail.offerDetails.offerData[
                        props.orderdetail.product_detail.offerDetails
                          .offerData.length - 1
                      ].paymentTerms
                      : props.orderdetail.product_detail.paymentType}
                    {props.orderdetail.product_detail.otherPayType
                      ? ` (${props.orderdetail.product_detail.otherPayType})`
                      : null}
                  </div>
                </div>
                <div className="value">
                  <div className="current-value">Order ID:</div>
                  <div className="new-value">{props.orderdetail.odid}</div>
                </div>
                <div className="value">
                  <div className="current-value">Order Date:</div>
                  <div className="new-value">
                    {moment(props.orderdetail.createdAt).format("DD-MM-YYYY")}
                  </div>
                </div>
                <div className="value">
                  <div className="current-value">
                    {userData.userType == "Buyer"
                      ? "Buyer Commission:"
                      : "Seller Commission"}
                  </div>
                  <div className="new-value">
                    ₹
                    {userData.userType == "Buyer"
                      ? props.orderdetail &&
                      (props.orderdetail.buyer_commission || "")
                      : props.orderdetail &&
                      (props.orderdetail.seller_commission || "")}
                  </div>
                </div>
                <div className="value">
                  <div className="current-value">Size:</div>
                  <div className="new-value">
                    {props.orderdetail.orderType === "Offered"
                      ? props.orderdetail.product_detail.offerDetails &&
                      props.orderdetail.product_detail.offerDetails
                        .offerData &&
                      props.orderdetail.product_detail.offerDetails.offerData
                        .length > 0 &&
                      props.orderdetail.product_detail.offerDetails.offerData[
                        props.orderdetail.product_detail.offerDetails
                          .offerData.length - 1
                      ].size
                      : props.orderdetail.product_detail.productSize}
                    mm
                  </div>
                </div>
                <div className="value">
                  <div className="current-value">Qty:</div>
                  <div className="new-value">
                    {props.orderdetail.order_item_qty}mt
                  </div>
                </div>
                <div className="value">
                  <div className="current-value">Delivery:</div>
                  <div className="new-value">{getDelieveryText(props)}</div>
                </div>
                {props.orderdetail.product_detail.insuranceCharge &&
                  props.orderdetail.product_detail.insuranceCharge
                    .$numberDecimal && (
                    <div className="value">
                      <div className="current-value">Insurance Charge:</div>
                      <div className="new-value">
                        ₹
                        {
                          props.orderdetail.product_detail.insuranceCharge
                            .$numberDecimal
                        }
                        /mt
                      </div>
                    </div>
                  )}
                {props.orderdetail.product_detail.loadingCharge &&
                  props.orderdetail.product_detail.loadingCharge
                    .$numberDecimal && (
                    <div className="value">
                      <div className="current-value">Loading Charge:</div>
                      <div className="new-value">
                        ₹
                        {
                          props.orderdetail.product_detail.loadingCharge
                            .$numberDecimal
                        }
                        /mt
                      </div>
                    </div>
                  )}
                <div className="value">
                {(props.orderdetail.product_detail.coilCount && props.orderdetail.product_detail.coilWeight) ?
                  <div className="current-value">Bundle Weight:</div>:  <div className="current-value">Packaging:</div>}
                  {(props.orderdetail.product_detail.coilCount && props.orderdetail.product_detail.coilWeight) ?
                  <span className="value">
                    {`${props.orderdetail.product_detail.coilCount} Coils (${props.orderdetail.product_detail.coilWeight} Kgs approx.)`}
                  </span>:
                   props.orderdetail.orderType === "Offered"
                    ? props.orderdetail.product_detail.offerDetails &&
                      props.orderdetail.product_detail.offerDetails.offerData &&
                      props.orderdetail.product_detail.offerDetails.offerData
                        .length > 0 &&
                      props.orderdetail.product_detail.offerDetails.offerData[
                        props.orderdetail.product_detail.offerDetails.offerData
                          .length - 1
                      ].packaging.map((item, index) => {
                        return (
                          <div className="new-value" key={index}>
                            {item}
                          </div>
                        );
                      })
                      : props.orderdetail.product_detail.packaging.map(
                        (item, index) => {
                          return (
                            <div className="new-value" key={index}>
                              {item}
                            </div>
                          );
                        }
                      )}
                </div>

                {props.orderdetail.order_status !== "Completed" ? (
                  <div className="value">
                    <div className="current-value">Delivery Window:</div>
                    <div className="new-value">
                      {moment(
                        props.orderdetail.orderType === "Offered"
                          ? props.orderdetail.product_detail.offerDetails &&
                          props.orderdetail.product_detail.offerDetails
                            .offerData &&
                          props.orderdetail.product_detail.offerDetails
                            .offerData.length > 0 &&
                          props.orderdetail.product_detail.offerDetails
                            .offerData[
                            props.orderdetail.product_detail.offerDetails
                              .offerData.length - 1
                          ].delivery_window_from
                          : props.orderdetail.product_detail.deliveryDateFrom
                      ).format("Do MMM")}{" "}
                      -{" "}
                      {moment(
                        props.orderdetail.orderType === "Offered"
                          ? props.orderdetail.product_detail.offerDetails &&
                          props.orderdetail.product_detail.offerDetails
                            .offerData &&
                          props.orderdetail.product_detail.offerDetails
                            .offerData.length > 0 &&
                          props.orderdetail.product_detail.offerDetails
                            .offerData[
                            props.orderdetail.product_detail.offerDetails
                              .offerData.length - 1
                          ].delivery_window_to
                          : props.orderdetail.product_detail.deliveryDateTo
                      ).format("Do MMM")}
                    </div>
                  </div>
                ) : (
                  <div className="value">
                    <div className="current-value">Delivered on:</div>
                    <div className="new-value">
                      {moment(
                        props.orderdetail.product_detail.current_status_time
                      ).calendar({
                        sameDay: "DD/MM/YYYY h:mm a",
                        nextDay: "DD/MM/YYYY h:mm a",
                        nextWeek: "DD/MM/YYYY h:mm a",
                        lastDay: "DD/MM/YYYY h:mm a",
                        lastWeek: "DD/MM/YYYY h:mm a",
                        sameElse: "DD/MM/YYYY h:mm a",
                      })}
                    </div>
                  </div>
                )}
                {props.orderdetail.isVisible == false ? (
                  <div className="count-wrap">
                    <p>Expires in:</p>
                    <span>
                      <Countdown
                        date={
                          new Date(props.orderdetail.createdAt).getTime() +
                          30 * 60 * 1000
                        }
                        renderer={renderer}
                        zeroPadTime={2}
                      />
                      &nbsp; mins
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="order-table-specs">
                <div className="current-value" style={{ marginBottom: "5px" }}>
                  Specification:
                </div>
                <div className="product-tble-wrp product-table-seller">
                  <table>
                    {props.orderdetail.product_detail.spec &&
                      Object.keys(props.orderdetail.product_detail.spec).map(
                        function (key, index) {
                          let filteredLength = []
                          const value = props.orderdetail.product_detail.spec[key];

                          // Skip if the value contains "N/A"
                          // if (value.includes("N/A")) {
                          //   return null;
                          // }

                          // Determine how to handle ranges and single values
                          let displayValue = value;
                          if (value.startsWith("N/A")) {
                            // Remove the key from the object
                            delete props.orderdetail.product_detail.spec[key];
                          } else {
                          if (value.includes("-")) {
                            // If it's a range (like "14-16%"), don't show "max" or "min"
                            displayValue = value.replace(/(max|min|N\/A)/i, "").trim();
                          } else if (value.match(/^\d+(\.\d+)?%$/)) {
                            // If it's a single percentage, add "max" or "min" based on the key

                            displayValue = `${value} ${key.toLowerCase().includes("min") ? "min" : "max"}`;
                          } else {
                            displayValue = value.replace(/(N\/A)/i, "").trim();
                          }
                          filteredLength.push(value)
                         }

                          return Object.keys(
                            filteredLength
                          ).length %
                            2 ===
                            1 ? (
                            Object.keys(filteredLength)
                              .length -
                              1 ===
                              index &&
                              Object.keys(filteredLength)
                                .length > 2 ? (
                              <>
                                {props.orderdetail.product_detail.spec[key] !==
                                  "% " ? (
                                  <>
                                    <tr>
                                      <th>
                                        {key.charAt(0).toUpperCase() +
                                          key.slice(1)}
                                      </th>
                                      <th>
                                        {displayValue}{" "}
                                      </th>
                                    </tr>
                                    <tr>
                                      <th
                                        style={{
                                          color: "transparent",
                                          borderRightColor: "transparent",
                                        }}
                                      >
                                        T
                                      </th>
                                      <th
                                        style={{
                                          color: "transparent",
                                          borderLeftColor: "transparent",
                                        }}
                                      >
                                        0
                                      </th>
                                    </tr>
                                  </>
                                ) : null}
                              </>
                            ) : (
                              <>
                                {props.orderdetail.product_detail.spec[key] !==
                                  "% " ? (
                                  <tr>
                                    <th>
                                      {key.charAt(0).toUpperCase() +
                                        key.slice(1)}
                                    </th>
                                    <th>
                                      {displayValue}{" "}
                                    </th>
                                  </tr>
                                ) : null}
                              </>
                            )
                          ) : (
                            <>
                              {props.orderdetail.product_detail.spec[key] !==
                                "% " ? (
                                <tr>
                                  <th>
                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                  </th>
                                  <th>
                                    {displayValue}{" "}
                                  </th>
                                </tr>
                              ) : null}
                            </>
                          );
                        }
                      )}
                  </table>
                </div>
              </div>
              {props &&
                props.orderdetail &&
                props.orderdetail.product_detail &&
                props.orderdetail.product_detail.additional_Comment &&
                props.orderdetail.product_detail.additional_Comment.length >
                0 && (
                  <div className="value">
                    <div className="current-value">
                      Additional Comment (
                      {props &&
                        props.orderdetail &&
                        props.orderdetail.product_detail &&
                        props.orderdetail.product_detail.latest_offerby &&
                        props.orderdetail.product_detail.latest_offerby}
                      ):
                    </div>
                    <div className="new-value">
                      {props.orderdetail.product_detail.additional_Comment}
                    </div>
                  </div>
                )}
            </div>
            <div className="approval-wrap new-approval-wrap progress-tab">
              {userData && userData.userType == "Buyer" ? (
                <>
                  {props.type == "Pending" ? (
                    <div className="status-wrapper status-pending">
                      <h5>
                        Order Status:{" "}
                        <span className="status" style={{ color: (props.orderdetail.order_status === "Processed" &&  props.orderdetail.paymentReceipts.length > 0) ? "#ed9829" :(props.orderdetail.order_status === "Processed")? "red":"inherit", }}>
                          {
                            (() => {
                              const { order_status, paymentReceipts } = props.orderdetail;

                              switch (order_status) {
                                case "ProformaInvoice":
                                  return "Proforma Invoice";
                                case "ReadyToShip":
                                  return "Ready To Ship";
                                case "Processed":
                                  return paymentReceipts && paymentReceipts.length > 0
                                    ? "Awaiting Payment Confirmation"
                                    : "EMD Payment Pending";
                                case "GoodsDispatched":
                                  return "Goods Dispatched";
                                case "OrderComplete":
                                  return "Order Complete";
                                case "GoodsReceived":
                                  return "Goods Received";
                                default:
                                  return order_status; // Fallback for unhandled statuses
                              }
                            })()
                          }
                        </span>
                      </h5>
                    </div>                    
                  ) : props.type == "Processed" ? (
                    <div className="status-wrapper status-pending">
                      <h5>
                        Status:{" "}
                        <span className="status">
                          {latestActiveStepName}
                        </span>
                      </h5>
                      {props.orderdetail.order_status === "Cancelled" ? (
                        <h5 style={{marginTop: "10px"}}>
                          Reason for Cancellation:{" "}
                          <span className="status">
                            {props.orderdetail.cancel_comment ? props.orderdetail.cancel_comment: "Please c the admin"}
                          </span>
                        </h5>
                      ): ''}
                      

                      <Link
                        className="shop-now msg-btn"
                        to={{
                          pathname: "/chats/all",
                          state: {
                            chatPayload: {
                              sender_id:
                                props.orderdetail &&
                                props.orderdetail.seller_id,
                              product_id:
                                props.orderdetail &&
                                props.orderdetail.product_detail &&
                                props.orderdetail.product_detail._id,
                              orderId:
                                props.orderdetail && props.orderdetail.order_id,
                            },
                            prevPageData: {
                              payload: {
                                orderId: "63cf6c6f61bab24c6542a9a5",
                                type: "Processed",
                              },
                              tab: "Processed",
                            },
                          },
                        }}
                      >
                        <i className="fas fa-envelope icon-chat-new" />
                      </Link>
                    </div>
                  ) : null}
                </>
              ) : (
                <div className="btn-approve-main">
                  {props.type == "Pending" ? (
                    <div className="approval-wrap">
                      <button
                        className="approve-btn btn"
                        onClick={() => acceptOrder(props.orderdetail)}
                      >
                        Confirm Payment
                      </button>
                      {/* <button
                        className="approve-btn btn"
                        onClick={() => {
                          setInpuForm(true);
                          setRejectOrderId(props.orderdetail._id);
                          setType("normal");
                        }}
                      >
                        Decline
                      </button> */}
                    </div>
                  ) : props.type == "Processed" ? (
                    <>
                    {/* Status is being calculated on client side */}
                      <div className="status-wrapper status-pending">
                        <h5>
                          Status:{" "}
                          <span className="status">
                            {latestActiveStepName}
                          </span>
                        </h5>
                        {props.orderdetail.order_status === "Cancelled" ? (
                        <h5 style={{marginTop: "10px"}}>
                          Reason for Cancellation:{" "}
                          <span className="status">
                            {props.orderdetail.cancel_comment ? props.orderdetail.cancel_comment: "Please contact the admin"}
                          </span>
                        </h5>
                      ): ''}
                        <Link
                          className="shop-now msg-btn"
                          to={{
                            pathname: "/chats/all",
                            state: {
                              chatPayload: {
                                sender_id:
                                  props.orderdetail &&
                                  props.orderdetail.customer_details &&
                                  props.orderdetail.customer_details
                                    .customer_id,
                                product_id:
                                  props.orderdetail &&
                                  props.orderdetail.product_detail &&
                                  props.orderdetail.product_detail._id,
                              },
                              prevPageData: {
                                payload: {
                                  orderId: "63cf6c6f61bab24c6542a9a5",
                                  type: "Processed",
                                },
                                tab: "Processed",
                              },
                            },
                          }}
                        >
                          <i className="fas fa-envelope icon-chat-new" />
                        </Link>
                      </div>
                    </>
                  ) : null}
                </div>
              )}
              {props.orderdetail.orderType && (
                <div
                  className="status-wrapper status-pending"
                  style={{ marginTop: "10px" }}
                >
                  <h5>
                    Order type:{" "}
                    <span className="new-value" style={{ lineHeight: "1.6" }}>
                      {props.orderdetail.orderType === "Offered"
                        ? "Offer"
                        : props.orderdetail.orderType === "Normal"
                          ? "Buy now"
                          : props.orderdetail.orderType}
                    </span>
                  </h5>
                </div>
              )}
            </div>
          </div>
        </div>

        {props.type === "Processed" &&
          userData &&
          (userData.userType == "Seller" || userData.userType == "Buyer") ? (
          <div div className="order-detail-top order-detail-wrapper">
            <div className="container order-container">
              <div className="order-top-wrapper order-delivery-wrap">
                {/* <ChangeTrack
                  orderData={props.orderdetail}
                  userType={userData.userType}
                  load={props.loadDetails}
                  completeOrderdetail={props.completeOrderdetail}
                /> */}
                <StepTimeline steps={steps}/>
              </div>
            </div>
          </div>
        ) : null}

        {props.type === "Processed" &&
          userData &&
          userData.userType == "Seller" && (
            <div div className="order-detail-top order-detail-wrapper">
              <div className="container order-container">
                <div className="order-delivery-wrap">
                  <span
                    className="order-title make-payment"
                    style={{ fontWeight: "700" }}
                  >
                    Proforma Invoice
                  </span>
                  <div className="order-top order-top-wrapper file-img-inputs">
                    {!invoiceData ? (
                      <div
                        className="shipping-module-wrp"
                        style={{ width: "145px" }}
                      >
                        {!fileAttach ? (
                          <div className="input-file-wrp-shipping">
                            <i class="fas fa-plus"></i>
                            <input
                              type="file"
                              className="file-in"
                              name="upload"
                              accept=".pdf"
                              onChange={(e) => fileHandler(e)}
                            />
                          </div>
                        ) : (
                          <div
                            className="uploaded-img-view"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <i
                              style={{ fontSize: "28px" }}
                              className={
                                fileAttach.type === "application/pdf"
                                  ? "fas fa-file-pdf"
                                  : "far fa-file"
                              }
                            ></i>
                          </div>
                        )}
                        <h6>Invoice</h6>
                        {
                          <span
                            className="error"
                            style={{
                              margin: "-8px 0 15px 0",
                              display: "block",
                            }}
                          >
                            {invoiceError ? "Please upload invoice" : ""}
                          </span>
                        }
                        <button
                          onClick={handleUploadInvoice}
                          class="common-btn"
                        >
                          Submit
                        </button>
                      </div>
                    ) : (
                      <a
                        href={invoiceData}
                        className="btn download-btn-wrp"
                        style={{
                          padding: "9px 23px",
                          color: "#198754",
                          boder: "1px solid #198754",
                        }}
                      >
                        Download <i class="fas fa-check"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        {props.type === "Processed" &&
          showReceivedSection &&
          userData &&
          userData.userType == "Seller" &&
          props.completeOrderdetail.received_shipping_status &&
          props.completeOrderdetail.received_shipping_status.length > 0 && (
            <div className="order-detail-top order-detail-wrapper received-order">
              <div className="container order-container">
                <div className="order-delivery-wrap">
                  <span
                    className="order-title make-payment"
                    style={{ fontWeight: "700" }}
                  >
                    Order Received
                  </span>

                  {props.completeOrderdetail.received_shipping_status &&
                    props.completeOrderdetail.received_shipping_status.map(
                      (item, index) => {
                        return item.media.length > 0 ? (
                          <div key={index}>
                            <h6>Lot {item.lot_number}</h6>
                            <div className="order-top order-top-wrapper file-img-inputs">
                              {item.media.map((ele, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="shipping-module-wrp"
                                    style={{ width: "100px" }}
                                  >
                                    <a
                                      href={ele.mediaData}
                                      target="_blank"
                                      className="uploaded-img-view"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img src={ele.mediaData} alt={"Images"} />
                                    </a>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : null;
                      }
                    )}
                </div>
              </div>
            </div>
          )}

        {props.type === "Processed" &&
          userData &&
          userData.userType &&
          props.completeOrderdetail &&
          props.completeOrderdetail.Data &&
          props.completeOrderdetail.Data.performaInvoice &&
          userData.userType == "Buyer" ? (
          <div div className="order-detail-top order-detail-wrapper">
            <div className="container order-container">
              <div className="order-top order-top-wrapper order-delivery-wrap">
                <div className="order-delivery-wrap">
                  <span
                    className="order-title make-payment"
                    style={{ fontWeight: "700" }}
                  >
                    Download Invoice
                  </span>
                  <a
                    target="_blank"
                    href={props.completeOrderdetail.Data.performaInvoice}
                    className="btn download-btn-wrp"
                    style={{
                      padding: "9px 23px",
                      color: "#198754",
                      boder: "1px solid #198754",
                    }}
                  >
                    Download <i class="fas fa-check"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {props.orderdetail.order_status === "Completed" &&
          userData &&
          userData.userType &&
          props.orderdetail &&
          props.orderdetail.performaInvoice ? (
          <div div className="order-detail-top order-detail-wrapper">
            <div className="container order-container">
              <div className="order-top order-top-wrapper order-delivery-wrap">
                <div className="order-delivery-wrap">
                  <span
                    className="order-title make-payment"
                    style={{ fontWeight: "700" }}
                  >
                    Download Invoice
                  </span>
                  <a
                    target="_blank"
                    href={props.orderdetail.performaInvoice}
                    className="btn download-btn-wrp"
                    style={{
                      padding: "9px 23px",
                      color: "#198754",
                      boder: "1px solid #198754",
                    }}
                  >
                    Download <i class="fas fa-check"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* ///////////////////////// Alok //////////////////////////////////// */}

        {
          ((userData && userData.userType && userData.userType == "Buyer") ||
            userData.userType == "Seller") ? (
            <div div className="order-detail-top order-detail-wrapper">
              <div className="container order-container">
                <div className="order-top order-top-wrapper order-delivery-wrap">
                  <MakePayment
                    orderData={props.orderdetail}
                    userType={userData.userType}
                    load={props.loadDetails}
                  />
                </div>
              </div>
            </div>
          ) : null}

        {props.type === "Processed" &&
          userData &&
          userData.userType == "Seller" ? (
          <div div className="order-detail-top order-detail-wrapper">
            <div className="container order-container">
              <div className="">
                {props.type === "Processed" &&
                  userData.userType == "Seller" && (
                    <div
                      className="shipping-module-wrp"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h2>Shipment Details</h2>
                      <div className="btn-wrapper-submit">
                        <button
                          className={`${!activeAddLot ? "disabled" : ""}`}
                          disabled={!activeAddLot}
                          style={{
                            textDecoration: "underline",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                          onClick={() => {
                            setFormCount([...formCount, formCount.length + 1]);
                            setActiveAddLot(false);
                          }}
                        >
                          Create New Lot
                        </button>
                      </div>
                    </div>
                  )}
                {formCount.map((item) => {
                  return (
                    <div
                      className={
                        props.type === "Processed" &&
                          userData.userType == "Seller"
                          ? "order-top order-top-wrapper order-delivery-wrap"
                          : ""
                      }
                      key={item}
                    >
                      <Shipping
                        activeAdd={setActiveAddLot}
                        number={item}
                        handleRemoveLot={handleRemoveLot}
                        orderData={props.orderdetail}
                        userType={userData.userType}
                        load={props.loadDetails}
                        completeOrderdetail={props.completeOrderdetail}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          userData &&
          props.completeOrderdetail && (
            <ShippingBuyer
              // number={item}
              orderData={props.orderdetail}
              userType={userData.userType}
              load={props.loadDetails}
              completeOrderdetail={props.completeOrderdetail}
            />
          )
        )}

        {props.orderdetail.order_status === "Completed" &&
          userData &&
          userData.userType == "Seller" && (
            <div div className="order-detail-top order-detail-wrapper">
              <div className="container order-container">
                <div className="">
                  <div
                    className="shipping-module-wrp"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h2>Shipment Details</h2>
                  </div>
                  {formCount.map((item) => {
                    return (
                      <div
                        className={
                          props.type === "Processed" &&
                            userData.userType == "Seller"
                            ? "order-top order-top-wrapper order-delivery-wrap"
                            : ""
                        }
                        key={item}
                      >
                        <Shipping
                          activeAdd={setActiveAddLot}
                          number={item}
                          handleRemoveLot={handleRemoveLot}
                          orderData={props.orderdetail}
                          userType={userData.userType}
                          load={props.loadDetails}
                          completeOrderdetail={props.completeOrderdetail}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        {props.orderdetail.order_status === "Completed" &&
          userData &&
          userData.userType == "Buyer" && (
            <ShippingModuleBuyer item={props.orderdetail.shippingData} />
          )}

        {/* Buyer side */}
        {props.type === "Processed" &&
          userData &&
          userData.userType == "Buyer" &&
          props.completeOrderdetail &&
          props.completeOrderdetail.Data &&
          props.completeOrderdetail.Data.shippingData &&
          props.completeOrderdetail.Data.shippingData.length > 0 && (
            <ShippingModuleBuyer
              item={props.completeOrderdetail.Data.shippingData}
            />
          )}

        {userData &&
          userData.userType == "Buyer" &&
          props.completeOrderdetail &&
          props.completeOrderdetail.bankData &&
          props.completeOrderdetail.bankData.ifsc ? (
          <div class="order-detail-top order-detail-wrapper">
            <div class="container order-container">
              <div class="order-top order-top-wrapper order-delivery-wrap">
                <div class="shipping-module-wrp shipping-module-wrp-new">
                  <h2>Seller Bank Details</h2>
                  <div
                    style={{ display: "flex", marginBottom: "10px" }}
                    className="user-detail"
                  >
                    <label
                      style={{
                        marginBottom: "0",
                        fontWeight: "700",
                        color: "#000",
                        marginRight: "9px",
                        lineHeight: "1.3",
                      }}
                    >
                      Bank Name:
                    </label>
                    <p>{props.completeOrderdetail.bankData.bankName}</p>
                  </div>
                  <div
                    style={{ display: "flex", marginBottom: "10px" }}
                    className="user-detail"
                  >
                    <label
                      style={{
                        marginBottom: "0",
                        fontWeight: "700",
                        color: "#000",
                        marginRight: "9px",
                        lineHeight: "1.3",
                      }}
                    >
                      Account Holder Name:
                    </label>
                    <p>
                      {props.completeOrderdetail.bankData.accountHolderName}
                    </p>
                  </div>
                  <div
                    style={{ display: "flex", marginBottom: "10px" }}
                    className="user-detail"
                  >
                    <label
                      style={{
                        marginBottom: "0",
                        fontWeight: "700",
                        color: "#000",
                        marginRight: "9px",
                        lineHeight: "1.3",
                      }}
                    >
                      Account Number:
                    </label>
                    <p>{props.completeOrderdetail.bankData.accountNo}</p>
                  </div>
                  <div
                    style={{ display: "flex", marginBottom: "10px" }}
                    className="user-detail"
                  >
                    <label
                      style={{
                        marginBottom: "0",
                        fontWeight: "700",
                        color: "#000",
                        marginRight: "9px",
                        lineHeight: "1.3",
                      }}
                    >
                      IFSC Code:
                    </label>
                    <p>{props.completeOrderdetail.bankData.ifsc}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {console.log("props", props)}

        {props.orderdetail.order_status === "Completed" &&
          userData &&
          userData.userType == "Buyer" ? null : (
          <div div className="order-detail-top order-detail-wrapper">
            <div className="container order-container">
              <div className="order-top order-top-wrapper order-delivery-wrap">
                {props.type === "Pending" ? (
                  <DeliveryAddress
                    seller_details={
                      props.completeOrderdetail &&
                      props.completeOrderdetail.Data &&
                      props.completeOrderdetail.Data.seller_details &&
                      props.completeOrderdetail.Data.seller_details.companyName
                    }
                    orderdetail={{
                      customer_id: props.orderdetail.customer_id,
                      customer_name: props.orderdetail.customer_name,
                      customer_details: props.orderdetail.customer_details,
                      product_detail: props.orderdetail.product_detail,
                    }}
                    userData={userData}
                  />
                ) : props.type === "Processed" ? (
                  <DeliveryAddress
                    orderdetail={props.orderdetail}
                    userData={userData}
                  />
                ) : null}

                {props.type === "Pending" ? null : (
                  <>
                    <div className="order-right">
                      <h4 style={{ marginBottom: "0" }}>Order Details</h4>
                      <ul>
                        <li>
                          <strong style={{ fontSize: "17px" }}>
                            {" "}
                            {props.orderdetail &&
                              props.orderdetail.seller_details &&
                              props.orderdetail.seller_details.companyName &&
                              props.orderdetail.seller_details.companyName}
                          </strong>
                        </li>
                        <li className="awaiting">
                          <span>Order Status:</span>{" "}
                          <strong
                            className={props.getClassName(
                              props.orderdetail.current_status
                            )}
                          >
                            {latestActiveStepName}
                          </strong>
                        </li>
                        {/* {props.payment.method === "COD" ? (
                          props.payment.status === "Completed" ? (
                            <li>
                              <span>Payment Status: </span>{" "}
                              <strong className="confirm">
                                {" "}
                                {props.payment.status}
                              </strong>
                            </li>
                          ) : null
                        ) : (
                          <li>
                            <span>Payment Status: </span>
                            <strong
                              className={props.getClassName(
                                props.payment.status
                              )}
                            >
                              {props.payment.status}
                            </strong>
                          </li>
                        )} */}
                      </ul>
                    </div>
                    <div className="down-btn d-none">
                      <ul>
                        <li>
                          <Link to="/contactUs" className="need-help">
                            <i className="fas fa-question-circle" />
                            &nbsp;need help
                          </Link>
                        </li>
                        {props.orderdetail.order_status === "Completed" && (
                          <li>
                            <span
                              className="send-inv"
                              onClick={() => props.sendInvoice()}
                            >
                              {" "}
                              Send Invoice{" "}
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {props.completeOrderdetail &&
          props.completeOrderdetail.reportedSellerData &&
          props.completeOrderdetail.reportedSellerData.length > 0 &&
          props.type === "Processed" &&
          userData &&
          userData.userType === "Buyer" ? (
          <div className="order-detail-top order-detail-wrapper order-receiver-column">
            <div className="container order-container">
              <div className="order-top order-top-wrapper order-delivery-wrap">
                <div
                  className="shipping-module-wrp order-right"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <h2>Order support</h2>
                  <ul>
                    <li>
                      <span>Reason:</span>{" "}
                      <strong>
                        {
                          props.completeOrderdetail.reportedSellerData[0]
                            .reasonTitle
                        }
                      </strong>
                    </li>
                    <li>
                      <span>Description:</span>{" "}
                      <strong>
                        {
                          props.completeOrderdetail.reportedSellerData[0]
                            .additionalComment
                        }
                      </strong>
                    </li>
                    <li>
                      <span>Images:</span>{" "}
                      <div className="feedback-image-wrapper">
                        {props.completeOrderdetail.reportedSellerData[0].media.map(
                          (item) => {
                            return (
                              <a
                                href={item.url}
                                target="_blank"
                                className="feedback-image-wrap"
                                key={item.mediaId}
                              >
                                <img src={item.url} alt="feedback file" />
                              </a>
                            );
                          }
                        )}
                      </div>
                    </li>
                  </ul>
                  {props &&
                    props.completeOrderdetail &&
                    props.completeOrderdetail.reportedSellerData &&
                    props.completeOrderdetail.reportedSellerData[0] &&
                    props.completeOrderdetail.reportedSellerData[0].adminData &&
                    props.completeOrderdetail.reportedSellerData[0]
                      .adminData[0] &&
                    props.completeOrderdetail.reportedSellerData[0].adminData[0]
                      .comment && (
                      <div className="admin-action-wrap">
                        <h6>Admin Response</h6>
                        {props.completeOrderdetail.reportedSellerData &&
                          props.completeOrderdetail.reportedSellerData[0] &&
                          props.completeOrderdetail.reportedSellerData[0]
                            .isStatus && (
                            <h6>
                              Status:{" "}
                              <span>
                                {props.completeOrderdetail.reportedSellerData[0]
                                  .isStatus
                                  ? "Closed"
                                  : "Pending"}
                              </span>
                            </h6>
                          )}
                        <p>
                          {
                            props.completeOrderdetail.reportedSellerData[0]
                              .adminData[0].comment
                          }
                        </p>
                        {props.completeOrderdetail.reportedSellerData[0]
                          .adminData[0].mediaImages && (
                            <div className="feedback-image-wrapper">
                              {props.completeOrderdetail.reportedSellerData[0].adminData[0].mediaImages.map(
                                (item) => {
                                  return (
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      className="feedback-image-wrap"
                                      key={item.mediaId}
                                    >
                                      <img src={item.url} alt="feedback file" />
                                    </a>
                                  );
                                }
                              )}
                            </div>
                          )}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {props.completeOrderdetail.received_shipping_status &&
          props.completeOrderdetail.received_shipping_status.length > 0 &&
          props.type !== "Pending" &&
          userData &&
          userData.userType == "Buyer" ? (
          <div className="order-detail-top order-detail-wrapper order-receiver-column">
            <div className="container order-container">
              <div className="order-top order-top-wrapper order-delivery-wrap">
                <div
                  className="shipping-module-wrp"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <h2>Order Status</h2>
                  <ul className="lots-listing order-received-lot">
                    {props.completeOrderdetail.received_shipping_status &&
                      props.completeOrderdetail.received_shipping_status.map(
                        (item, index) => {
                          return (
                            <OrderReceived
                              setAllReceived={setAllReceived}
                              item={item}
                              key={index}
                            />
                          );
                        }
                      )}
                  </ul>
                  <div className="order-final-buttons">
                    {props.completeOrderdetail &&
                      props.completeOrderdetail.reportedSellerData &&
                      props.completeOrderdetail.reportedSellerData.length === 0 &&
                      props.type === "Processed" &&
                      userData &&
                      userData.userType == "Buyer" ? (
                      <button
                        onClick={() => setFeedbackPopup(true)}
                        className="feedback-seller common-btn"
                      >
                        Order support
                      </button>
                    ) : null}
                    {!receiveOrders && (
                      <button
                        onClick={handleReceiveAll}
                        className="tracking-status-btn received-all-orders"
                        disabled={!allReceived}
                      >
                        Received all orders
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* <DeliveryStep allSteps={allSteps} /> */}
        {/* /////////////////////////////// Alok //////////////////////////////////// */}

        {/* ////////////////// Find Carrier 1st Modal ////////////////// */}
        <Modal
          className="pendingorder-popup ready-ship-popup-wrap"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            {/* <Modal.Title className='enter-dimension'>Enter Price</Modal.Title> */}
            <button
              className="modal-closeBtn payment-link-close payment-close-wrap ready-ship-close"
              onClick={close}
              type="button"
            >
              <i className="fal fa-times-circle"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <>
              <Form onSubmit={(e) => carrierDetail(e)}>
                <Form.Group
                  className="mb-3 price-form-wrap"
                  controlId="formBasicEmail"
                >
                  <Form.Label>Enter Price</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Price"
                    onChange={(e) => setwidth(e.target.value)}
                    value={width}
                    required
                  />
                  <Form.Text className="text-muted"></Form.Text>
                </Form.Group>
                <br />
                <div className="upload-document order-upload">
                  <h5>Browse Receipt</h5>
                  <div className="file-upload-wrapper">
                    <div className="upload-file">
                      <input type="file" className="file-uploader" />
                      <img src="/assets/images/upload-icn.svg" alt="img-icon" />
                    </div>
                  </div>
                  <div className="uploaded-image">
                    <img src="" alt="uploaded-img" />
                  </div>
                </div>
                <div className="buttons-wrp">
                  <Button
                    type="submit"
                    className="submit-button"
                    variant="primary"
                  >
                    submit
                  </Button>
                </div>
              </Form>
            </>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        {/* ////////////////// Find Carrier 2nd Modal ////////////////// */}
        <Modal
          className="pendingorder-popup carrier-main-wrapper"
          show={show1}
          onHide={() => handleClose1()}
        >
          <Modal.Header closeButton>
            <Modal.Title className="carrier-head">Find Carrier</Modal.Title>
            {/* <i className="fas fa-arrow-left"></i> */}
            <button
              className="modal-closeBtn payment-link-close payment-close-wrap ready-ship-close"
              onClick={() => handleClose1()}
              type="button"
            >
              <i className="fal fa-times-circle"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            {costEstimateListDetail.length != 0 ? (
              <>
                <div className="wrpval-ipt">
                  {costEstimateListDetail.map((item, index) => {
                    return (
                      <>
                        <div className="ordered-products carrierList">
                          <div className="order-items">
                            <div className="cond">
                              <input
                                type="radio"
                                value=""
                                // checked={item.makeDefault}
                                name="address"
                                className="radio"
                                onChange={() => {
                                  getOrder(item.carrierCode);
                                }}
                              />
                              <p>{item.carrierName}</p>
                              <p>
                                {item.costEstimates.length > 0 &&
                                  item.costEstimates[0].totalAmount}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>

                <div className="buttons-wrp">
                  <Button
                    type="button"
                    className="submit-button"
                    variant="primary"
                    onClick={() => previous()}
                  >
                    Prev
                  </Button>
                  {/* <Button className="submit-button cancel-btn" variant="secondary" onClick={() => handleClose1()}>
                                        Close
                                    </Button> */}
                </div>
              </>
            ) : (
              <h4 className="no-data-found">No Carrier Found</h4>
            )}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>

      {feedbackPopup ? (
        <div
          className="make-offer-popup-new feedback-popup"
          style={{ zIndex: "10" }}
        >
          <div className="offer-popup-content">
            <div className="offer-popup-content-header">
              <h3>Feedback</h3>
              <button
                className=""
                type="button"
                onClick={() => setFeedbackPopup(false)}
              >
                <i className="fal fa-times-circle"></i>
              </button>
            </div>
            <div className="slct-optns select-wrapper">
              <div className="form-block-wrap">
                <label className="enter-mobile-label">
                  Select
                  <span className="highlighted">*</span>
                </label>
                <div className="select-main-wrapper">
                  <select
                    className="make-offer-select-tag"
                    value={selectFeedback}
                    onChange={handleSelectFeedback}
                  >
                    <option hidden>Select...</option>
                    {reasonList
                      ? reasonList.map((item) => {
                        return (
                          <option value={item._id} key={item._id}>
                            {item.title}
                          </option>
                        );
                      })
                      : null}
                  </select>
                  {errorSelectFeedback && <p>Please select feedback</p>}
                  {feedbackReason && (
                    <div style={{ marginTop: "10px" }}>{feedbackReason}</div>
                  )}
                </div>
              </div>

              <div className="form-block-wrap">
                <label className="enter-mobile-label">
                  Description
                  <span className="highlighted">*</span>
                </label>
                <div className="form-input">
                  <textarea
                    value={commentBox}
                    placeholder="Enter Description"
                    onChange={(e) => {
                      setCommentBox(e.target.value);
                      setErrorDescription(false);
                    }}
                    rows={3}
                  ></textarea>
                </div>
                {errorDescription && <p>Please enter description</p>}
              </div>
              <div className="makepaymentmain make-payment-new-wrp">
                <label>
                  Attach File<span className="highlighted">*</span>
                </label>
                <div className="inputfile">
                  <span className="shipping-module-wrp">
                    <span className="input-file-wrp-shipping">
                      <i
                        class={`${imageAttach.length > 0
                            ? "green-plus-icon fas fa-check"
                            : "fas fa-plus"
                          }`}
                      ></i>
                      <input
                        type="file"
                        className="file-in"
                        name="upload"
                        accept=".jpg ,.jpeg,.png"
                        onChange={handleFeedbackFiles}
                        multiple={true}
                      />
                    </span>
                    {imageAttach.length > 0 && (
                      <div>{`${imageAttach.length} file${imageAttach.length > 1 ? "s" : ""
                        } uploaded`}</div>
                    )}
                    {errorImages && <p>Please upload files</p>}
                  </span>
                  {/* <input type="file" onChange={handleFeedbackFiles} name="myImage" multiple={true} style={{ "display": "block" }} /> */}
                </div>
              </div>
              <div
                className="navigation-back"
                style={{ flexDirection: "column" }}
              >
                <button onClick={handleFeedbackSubmit} className="next-btn">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {showInputForm && (
        <InputReason
          closePopup={() => {
            setInpuForm(false);
            setRejectOrderId("");
            setType("");
          }}
          content={`Please type the reason for reject`}
          submitFun={(reason) => {
            if (reason.length > 20) {
              if (type === "normal") {
                rejectOrder(rejectOrderID, reason);
              } else {
                rejectOffer(rejectOrderID, reason);
              }
              setInpuForm(false);
            }
          }}
        />
      )}
      {reviseOfferPopup && (
        <ReviceOffer
          closePopup={() => {
            setReviseOffer(false);
            setRejectOrderId("");
          }}
          submitFun={(price) => {
            revisedOffer(price);
            setReviseOffer(false);
          }}
        />
      )}
    </div>
  ) : null;
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    Cost_Estimation: (payload, completion) =>
      dispatch(Cost_Estimation(payload, completion)),
    stock_entry_header: (payload, completion) =>
      dispatch(stock_entry_header(payload, completion)),
    generate_label: (payload, completion) =>
      dispatch(generate_label(payload, completion)),
    generate_manifest: (payload, completion) =>
      dispatch(generate_manifest(payload, completion)),
    getorderDetail: (payload, data, downloadLink) =>
      dispatch(getorderDetail(payload, data, downloadLink)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PendingOrderDetail)
);
