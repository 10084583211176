import React from "react";

const StepTimeline = ({ steps }) => {
  return (
    <div className="order-timeline status-timeline" style={{flexBasis: "90%"}}>
      <ul className="timline order-process-list" style={{justifyContent: "center"}}>
        {steps.map((step, index) => (
          <li
            key={index}
            className={step.isActive ? "active" : ""}
          >
            <span className="circle-timeline"></span>
            <span className="timeline-text" style={{ fontWeight: step.isActive ? "bold" : "normal" }}>
              {step.name === "Completed" ? "Delivered" : step.name}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StepTimeline;
